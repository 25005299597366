<template>
  <v-container fluid>
    <v-row dense align="center">
      <v-col cols="auto" md="2">
        <v-btn color="primary" small to="/main/user-admin/create">
          {{ $_strings.userAdmin.CREATE_USERADMIN_BUTTON_LABEL }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="10">
        <v-row no-gutters justify-md="end">
          <v-col cols="auto">
            <filter-list :filters="filters" @fetchData="fetching" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <List :key="key_list" :filters="filters" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import List from './List.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'UserAdminList',
  components: {
    List,
    FilterList,
  },
  data() {
    return {
      key_list: 0,
      filters: {
        username: this.$route.query.username || '',
        email: this.$route.query.email || '',
        activated: this.$route.query.activated ? this.$route.query.activated === 'true' : '',
        rolesId: +this.$route.query.rolesId || '',
      },
    };
  },
  methods: {
    fetching() {
      this.key_list += 1;
    },
    setFilter({ filterName, value }) {
      if (this.$route.query[filterName] !== JSON.stringify(value)) {
        this.filters[filterName] = value;
        this.$router.replace({
          query: {
            ...this.$route.query,
            [filterName]: value,
            page: 1,
          },
        });
      }
      this.fetching();
    },
  },
};
</script>
