<template>
  <div class="text-center d-flex">
    <v-menu
      bottom
      :close-on-content-click="closeOnContentClick"
      width="300"
      offset-y
      z-index="1"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          height="10"
          class="caption"
          prepend-inner-icon="mdi-filter"
          :placeholder="$_strings.order.FILTER"
          outlined
          dense
          readonly
          v-bind="attrs"
          @click="closeOnContentClick = false"
          v-on="on"
        ></v-text-field>
      </template>
      <v-list width="300" height="500" class="overflow-auto">
        <section v-for="item in filterItems" :key="item.id">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="item.type === 'textfield'">
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                hide-details
                class="caption"
                outlined
                dense
                v-model="form[item.vModel]"
                v-mask="item.mask ? { alias: item.mask, allowMinus: false, rightAlign: false } : ''"
                clearable
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'activated'">
            <v-list-item-content class="pt-0 mt-0">
              <v-select
                v-model="form[item.vModel]"
                :items="statusItems"
                :placeholder="$_strings.userAdmin.STATUS_USER_ADMIN"
                outlined
                hide-details
                class="caption mr-2"
                dense
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'role'">
            <v-list-item-content class="pt-0 mt-0">
              <common-auto-complete-items
                type="listRoleItems"
                searchName="rolesName"
                item-value="rolesId"
                item-text="rolesName"
                v-model="form[item.vModel]"
                dense
                outlined
                class="caption"
                clearable
                placeholder="Role"
                hide-details
                :filter="rolesFilter"
                :items="itemsRoles"
                @updateItems="updateListItemsRoles"
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section class="pl-2 pr-2">
          <v-btn depressed color="primary" block @click="setFilter">
            {{ $_strings.common.APPLY }}
          </v-btn>
          <v-btn
            depressed
            class="mt-2 white--text"
            color="red"
            block
            @click="closeOnContentClick = true"
          >
            {{ $_strings.common.CLOSE }}
          </v-btn>
        </section>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const { rolesId, rolesName } = this.$route.query;
    if (rolesId && rolesName) {
      this.itemsRoles = [{
        rolesId: +rolesId,
        rolesName,
      }];
    }
  },
  data() {
    return {
      filterItems: [
        {
          id: 'username',
          title: 'Nama',
          vModel: 'username',
          type: 'textfield',
        },
        {
          id: 'email',
          title: 'Email',
          vModel: 'email',
          type: 'textfield',
        },
        {
          id: 'rolesId',
          title: 'Role',
          vModel: 'rolesId',
          type: 'role',
        },
        {
          id: 'activated',
          title: 'Status',
          vModel: 'activated',
          type: 'activated',
        },
      ],
      form: {
        username: this.$route.query.username || '',
        email: this.$route.query.email || '',
        activated: this.$route.query.activated ? this.$route.query.activated === 'true' : '',
        rolesId: +this.$route.query.rolesId || '',
      },
      rolesFilter: {
        search: '',
        page: 0,
        size: 50,
        totalData: 0,
      },
      itemsRoles: [],
      statusItems: [
        {
          text: 'Semua Status',
          value: '',
        },
        {
          text: 'Aktif',
          value: true,
        },
        {
          text: 'Tidak Aktif',
          value: false,
        },
      ],
      closeOnContentClick: false,
    };
  },
  methods: {
    updateListItemsRoles(items) {
      this.itemsRoles = [...this.itemsRoles, ...items];
    },
    setFilter() {
      this.closeOnContentClick = true;
      const {
        username,
        email,
        activated,
        rolesId,
      } = this.form;
      const oldFilters = { ...this.filters };
      this.filters.username = username;
      this.filters.email = email;
      this.filters.activated = activated;
      this.filters.rolesId = rolesId;
      if (JSON.stringify(oldFilters) !== JSON.stringify(this.filters)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...this.filters,
            page: 1,
            rolesName: rolesId ? this.itemsRoles.find((x) => x.rolesId === rolesId).rolesName : '',
          },
        });
      }
      this.$emit('fetchData');
    },
  },
};
</script>
