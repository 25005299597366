<template>
  <v-data-table
    :loading="isLoading"
    :headers="displayedHeader"
    :items="items"
    item-key="id"
    :server-items-length="userAdminListTotalEntry"
    :options.sync="pagination"
    @click:row="rowClicked"
    :footer-props="{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': $_item_per_page,
    }"
  >
    <template v-slot:[`item.activated`]="{ item }">
      <v-row no-gutters>
        <v-col :cols="9">
          <v-select
            :disabled="
              !item.action || item.companyUserId === myUserInfo.companyUserId || !item.activated
            "
            :value="item.activated"
            :items="userActivationOption"
            hide-details
            dense
            solo
            rounded
            class="text-body-2"
            @input="val => toggleUserActivation(item, val)"
            @click.stop.prevent
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            v-if="item.companyUserId !== myUserInfo.companyUserId"
            icon
            color="error"
            fab
            small
            @click.stop.prevent="() => deleteUser(item)"
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.actionColumn`]="{ item }">
      <v-btn
        v-if="item.action && item.companyUserId !== myUserInfo.companyUserId && item.activated"
        color="primary"
        rounded
        small
        @click.stop.prevent="() => resetPassword(item)"
      >
        {{ $_strings.userAdmin.RESETPASSWORD_BTN_LABEL }}
      </v-btn>
      <v-btn
        v-if="item.activated === false"
        color="warning"
        rounded
        small
        min-width="130"
        @click.stop.prevent="() => resendEmail(item)"
      >
        Resend Email
      </v-btn>
    </template>
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{ $_strings.userAdmin.PAGE_NAME }}
        <span v-if="items.length">
          {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength }}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'UserAdminList',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.userAdmin.NAME_TABLE_HEADER_LABEL,
          value: 'username',
        },
        {
          text: this.$_strings.userAdmin.EMAIL_TABLE_HEADER_LABEL,
          value: 'email',
        },
        {
          text: this.$_strings.userAdmin.ROLE_TABLE_HEADER_LABEL,
          value: 'roleRolesName',
          width: '14vw',
        },
        {
          text: this.$_strings.userAdmin.STATUS_TABLE_HEADER_LABEL,
          value: 'activated',
          width: '17vw',
        },
        {
          text: '',
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          width: '8vw',
          sortable: false,
        },
      ],
      pagination: this.defaultPagination(),
      userAdminListTotalEntry: 0,
      filterBy: [
        {
          text: 'Semua Status',
          value: '',
        },
        {
          text: 'Aktif',
          value: true,
        },
        {
          text: 'Tidak Aktif',
          value: false,
        },
      ],
      userActivationOption: [
        {
          text: this.$_strings.userAdmin.ACTIVATED_OPTION_LABEL,
          value: true,
        },
        {
          text: this.$_strings.userAdmin.INACTIVATED_OPTION_LABEL,
          value: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchUserAdmins();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    async rowClicked(e, { item }) {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.userAdmins.fetchDetail(
          item.companyId,
          item.companyUserId,
        );
        this.$router.push({
          name: 'user-admin-edit-page',
          params: {
            companyId: item.companyId,
            userId: item.companyUserId,
            data: result.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchUserAdmins() {
      const {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'createdAt,desc', sortBy, sortDesc }),
        ...this.filters,
      };
      if (filters.username) filters.username = `qLike(${filters.username})`;
      if (filters.email) filters.email = `qLike(${filters.email})`;
      try {
        this.isLoading = true;
        const res = await this.$_services.userAdmins.fetchList(this.skipEmptyStringObject(filters));
        this.items = res.data.contents;
        this.userAdminListTotalEntry = res.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async toggleUserActivation(item, val) {
      try {
        this.$root.$loading.show();
        await this.$_services.userAdmins.createEdit(
          { ...item, activated: val },
          item.companyUserId,
        );
      } catch {
        this.$nextTick(() => {
          item.activated = !val;
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    resetPassword(item) {
      this.$root.$loading.show();
      this.$_services.userAdmins
        .resetPassword(item)
        .then(() => {
          this.$dialog.notify.success(
            this.$_strings.userAdmin.SUCCESS_RESET_PASSWORD_USER_MESSAGE_TEXT,
          );
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    resendEmail(item) {
      this.$root.$loading.show();
      this.$_services.userAdmins
        .resendEmail(item)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_RESEND_USER_MESSAGE_TEXT);
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    deleteUser(item) {
      this.$dialog
        .warning({
          text: this.$_strings.userAdmin.DELETE_USER_CONFIRM_MESSAGE,
          title: this.$_strings.common.WARNING,
          persistent: true,
          actions: {
            false: this.$_strings.common.NO,
            true: this.$_strings.common.YES,
          },
        })
        .then((userRes) => {
          if (userRes) {
            this.$root.$loading.show();
            this.$_services.userAdmins
              .deleteUser(item)
              .then((res) => {
                this.$dialog.notify.success(
                  this.$_strings.userAdmin.SUCCESS_DELETE_USER_MESSAGE_TEXT,
                );
                this.fetchUserAdmins();
              })
              .finally(() => {
                this.$root.$loading.hide();
              });
          }
        });
    },
  },
};
</script>
