var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","server-items-length":_vm.userAdminListTotalEntry,"options":_vm.pagination,"footer-props":{
    showCurrentPage: true,
    showFirstLastPage: true,
    'items-per-page-options': _vm.$_item_per_page,
  }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.activated",fn:function(ref){
  var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":9}},[_c('v-select',{staticClass:"text-body-2",attrs:{"disabled":!item.action || item.companyUserId === _vm.myUserInfo.companyUserId || !item.activated,"value":item.activated,"items":_vm.userActivationOption,"hide-details":"","dense":"","solo":"","rounded":""},on:{"input":function (val) { return _vm.toggleUserActivation(item, val); },"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(item.companyUserId !== _vm.myUserInfo.companyUserId)?_c('v-btn',{attrs:{"icon":"","color":"error","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.deleteUser(item); })($event)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1):_vm._e()],1)],1)]}},{key:"item.actionColumn",fn:function(ref){
  var item = ref.item;
return [(item.action && item.companyUserId !== _vm.myUserInfo.companyUserId && item.activated)?_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.resetPassword(item); })($event)}}},[_vm._v(" "+_vm._s(_vm.$_strings.userAdmin.RESETPASSWORD_BTN_LABEL)+" ")]):_vm._e(),(item.activated === false)?_c('v-btn',{attrs:{"color":"warning","rounded":"","small":"","min-width":"130"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.resendEmail(item); })($event)}}},[_vm._v(" Resend Email ")]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.userAdmin.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }